export function createPolygonFromRectangle(rectangle: any) {
	const map = rectangle.getMap();

	const coords = [
		{
			lat: rectangle.getBounds().getNorthEast().lat(),
			lng: rectangle.getBounds().getNorthEast().lng(),
		},
		{
			lat: rectangle.getBounds().getNorthEast().lat(),
			lng: rectangle.getBounds().getSouthWest().lng(),
		},
		{
			lat: rectangle.getBounds().getSouthWest().lat(),
			lng: rectangle.getBounds().getSouthWest().lng(),
		},
		{
			lat: rectangle.getBounds().getSouthWest().lat(),
			lng: rectangle.getBounds().getNorthEast().lng(),
		},
	];

	// Construct the polygon.

	const rectPoly = new google.maps.Polygon({
		// @ts-ignore
		path: coords,
		draggable: true,
		editable: true,
		geodesic: true,
	});
	const properties = [
		"strokeColor",
		"strokeOpacity",
		"strokeWeight",
		"fillOpacity",
		"fillColor",
	];
	//inherit rectangle properties
	const options = {};

	properties.forEach(function (property) {
		if (rectangle.hasOwnProperty(property)) {
			// @ts-ignore
			options[property] = rectangle[property];
		}
	});
	rectPoly.setOptions(options);
	rectangle.setMap(null);
	rectPoly.setMap(map);

	return rectPoly;
}

export function rotatePolygon(polygon: any, angle: number) {
	const map = polygon.getMap();
	const prj = map.getProjection();
	const center = polygon.getPath().getAt(0);
	const origin = prj.fromLatLngToPoint(center); //rotate around first point

	const coords = polygon
		.getPath()
		.getArray()
		.map(function (latLng) {
			const point = prj.fromLatLngToPoint(latLng);
			const rotatedLatLng = prj.fromPointToLatLng(
				rotatePoint(point, origin, angle - 180)
			);
			return { lat: rotatedLatLng.lat(), lng: rotatedLatLng.lng() };
		});

	polygon.setPath(coords);
}

export function rotatePoint(point, origin, angle) {
	const angleRad = (angle * Math.PI) / 180.0;

	return {
		x:
			Math.cos(angleRad) * (point.x - origin.x) -
			Math.sin(angleRad) * (point.y - origin.y) +
			origin.x,
		y:
			Math.sin(angleRad) * (point.x - origin.x) +
			Math.cos(angleRad) * (point.y - origin.y) +
			origin.y,
	};
}

export function calcBounds(center, size) {
	const n = google.maps.geometry.spherical.computeOffset(center, 0, 0).lat(),
		s = google.maps.geometry.spherical
			.computeOffset(center, size.height, 180)
			.lat(),
		e = google.maps.geometry.spherical.computeOffset(center, 0, 90).lng(),
		w = google.maps.geometry.spherical
			.computeOffset(center, size.width, 270)
			.lng();

	return [
		new google.maps.LatLng(n, e),
		new google.maps.LatLng(s, e),
		new google.maps.LatLng(s, w),
		new google.maps.LatLng(n, w),
	];
}

export function generateBounds(center, height, width) {
	const n = google.maps.geometry.spherical.computeOffset(center, 0, 0).lat(),
		s = google.maps.geometry.spherical.computeOffset(center, height, 180).lat(),
		e = google.maps.geometry.spherical.computeOffset(center, 0, 90).lng(),
		w = google.maps.geometry.spherical.computeOffset(center, width, 270).lng();
	return new google.maps.LatLngBounds(
		new google.maps.LatLng(s, w),
		new google.maps.LatLng(n, e)
	);
}

// Set the visibility to 'hidden' or 'visible'.