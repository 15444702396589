import { Route, Routes } from "react-router-dom";

import GoogleMapPage from "./modules/GoogleMapPage";
import Layout from "./components/Layout";
import NoMatch from "./modules/NoMatch";
import MainPage from "./modules/MainPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<MainPage />} />
        <Route path="google" element={<GoogleMapPage />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

export default App;
