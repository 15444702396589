import React, {useEffect, useState} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import fieldImages from "../../api/field-images";

const Form = ({currentPolygon, updateBounds, removePolygon, changeImage, closeEditing }) => {
	const [width, setWidth] = useState(currentPolygon.calculatedWidth);
	const [height, setHeight] = useState(currentPolygon.calculatedHeight);
	const [angle, setAngle] = useState(currentPolygon.calculatedAngle);
	// const [image, setImage] = useState('./default-field.svg');

	const updateWidth = (val) => {
		setWidth(val)
		updateBounds({ width: val, height, angle })
	}

	const updateHeight = (val) => {
		setHeight(val)
		updateBounds({ width, height: val, angle })
	}

	const updateAngle = (val) => {
		setAngle(val)
		updateBounds({ width, height, angle: val })
	}

	useEffect(() => {
		setWidth(currentPolygon.calculatedWidth);
		setHeight(currentPolygon.calculatedHeight);
		setAngle(currentPolygon.calculatedAngle);

		return () => {
			currentPolygon.setOptions({fillColor: 'black', strokeColor: 'black'});
		}

	}, [currentPolygon])

	return (
		<div className={'poly_plot'}>
			<div className={'poly_plot_container'}>
				<div className={'field-images'}>
					<label className={'field-images_label'}>Image</label>
					{fieldImages.map(field => (
						<button
							key={field.id}
							onClick={changeImage(field.url)}
							className={"field-images_button"}
						>
							<img
								alt={field.title}
								src={field.url}
								className={"field-images_preview"}
								title={field.title}
							/>
						</button>
					))}
				</div>

				<div className={'poly_plot_input'}>
					<label>Width</label>
					<input name="width" type="text" value={width} onChange={(e) => updateWidth(parseInt(e.target.value) || 1)}/>
				</div>

				<div  className={'poly_plot_input'}>
					<label>Height</label>
					<input name="height" type="text" value={height} onChange={(e) => updateHeight(parseInt(e.target.value) || 1)}/>
				</div>

				<div className={'poly_plot_input'}>
					<label>Angle</label>
					<div className={'poly_plot_selectWrap'}>
						<button onClick={() => updateAngle(angle > 0 ? angle - 1 : 0)}>-</button>
						<select value={angle} name="angle" onChange={(e) => updateAngle(e.target.value)}>
							{Array.from({length: 361}).map((_, index) => <option key={index} value={index}>{index - 180}</option>)}
						</select>
						<button onClick={() => updateAngle( angle < 360 ? angle + 1 : 360)}>+</button>
					</div>
				</div>
				<div className={'poly_plot_slider'}>
					<Slider
						onChange={(nextValues) => updateAngle(nextValues)}
						value={angle}
						min={0}
						max={360}
						step={1}
					/>
				</div>
				<div className="poly_plot_buttons">
					<button className="poly_plot_buttons_save" onClick={closeEditing}>save</button>
					<button className="poly_plot_buttons_remove" onClick={removePolygon}>remove</button>
				</div>
			</div>
		</div>
	);
};

export default Form;