import {GoogleMap, LoadScript} from "@react-google-maps/api";
import MapContent from "./MapContent";

const GoogleMapPage = () => {
	return (
		<div style={{ width: '100%', height: '100%' }}>
			<LoadScript googleMapsApiKey="AIzaSyAeI1DE28Ygp0S1eODIp3jbIqw01TPJ6tg" libraries={["geometry"]}>
				<GoogleMap
					mapContainerStyle={{ width: '100%', height: '100%' }}
					center={{ lat: 0, lng: 0 }}
					zoom={2}
					mapTypeId="hybrid"
				>
					<MapContent/>
				</GoogleMap>
			</LoadScript>
		</div>
	);
};

export default GoogleMapPage;