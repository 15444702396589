export default [
  {
    id: 1,
    url: './default-field.svg',
    title: 'Default field'
  },
  {
    id: 2,
    url: './football-field.png',
    title: 'Football field'
  },
  {
    id: 3,
    url: './soccer-field.png',
    title: 'Soccer field'
  }
]
