import { useGoogleMap } from "@react-google-maps/api";
import "./styles.scss";

import {
	createPolygonFromRectangle,
	rotatePolygon,
	calcBounds,
	generateBounds,
} from "./helpers";
import { useState } from "react";
import Form from "./Form";

const MapContent = () => {
	let overlay = null;
	USGSOverlay.prototype = new google.maps.OverlayView();

  const map = useGoogleMap();
	const [active, setActive] = useState(null);
	const [imageURL, setImageUrl] = useState("./soccer-field.png");

  const addRectangle = () => {
	  const bounds = map.getBounds();
	  const sss = Math.round(
		  google.maps.geometry.spherical.computeDistanceBetween(
			  bounds.getNorthEast(),
			  bounds.getSouthWest()
		  ) / 10
	  );

    const rectangle = new google.maps.Rectangle({
		draggable: true,
		editable: true,
		bounds: generateBounds(map?.getCenter(), sss, sss),
	});

    rectangle.setMap(map);

    google.maps.event.addListener(rectangle, "click", function () {
		const rectPoly: any = createPolygonFromRectangle(rectangle); //create a polygon from a rectangle

      overlay = new USGSOverlay(rectangle.getBounds(), imageURL, map, rectPoly);

      const width = Math.round(
		  google.maps.geometry.spherical.computeDistanceBetween(
			  {
				  lat: rectangle.getBounds().getNorthEast().lat(),
				  lng: rectangle.getBounds().getNorthEast().lng(),
			  },
			  {
				  lat: rectangle.getBounds().getNorthEast().lat(),
				  lng: rectangle.getBounds().getSouthWest().lng(),
			  }
		  )
	  );

      const height = Math.round(
		  google.maps.geometry.spherical.computeDistanceBetween(
			  {
				  lat: rectangle.getBounds().getNorthEast().lat(),
				  lng: rectangle.getBounds().getNorthEast().lng(),
			  },
			  {
				  lat: rectangle.getBounds().getSouthWest().lat(),
				  lng: rectangle.getBounds().getNorthEast().lng(),
			  }
		  )
	  );

		// @ts-ignore
		rectPoly.calculatedWidth = width;
		// @ts-ignore
		rectPoly.calculatedHeight = height;
		// @ts-ignore
		rectPoly.calculatedAngle = 180;

      rectPoly.addListener("click", () => {
		  rectPoly.setOptions({ fillColor: "orange", strokeColor: "orange" });
		  setActive(rectPoly);
	  });

      rectPoly.addListener("mousemove", () => {
		  const boundss = new google.maps.LatLngBounds(
			  new google.maps.LatLng(rectPoly.C.bounds.xa, rectPoly.C.bounds.oa),
			  new google.maps.LatLng(rectPoly.C.bounds.Ca, rectPoly.C.bounds.ya)
		  );
		  overlay.setBounds(boundss);
		  overlay.setTransform(rectPoly.calculatedAngle);
	  });
		rectPoly.addListener("drag", () => {
			const boundss = new google.maps.LatLngBounds(
				new google.maps.LatLng(rectPoly.C.bounds.xa, rectPoly.C.bounds.oa),
				new google.maps.LatLng(rectPoly.C.bounds.Ca, rectPoly.C.bounds.ya)
			);
			overlay.setBounds(boundss);
			overlay.setColorBg("green");
		});
		// @ts-ignore
		this.setMap(null);
	});
  };

  const updateBounds = (current, width, height, angle) => {
	  const centerLat = current.getPath().getAt(0).lat();
	  const centerLng = current.getPath().getAt(0).lng();

    const path = calcBounds(
		new google.maps.LatLng(centerLat, centerLng),
		new google.maps.Size(width, height)
	);
	  current.setPath(path);
	  rotatePolygon(current, angle);
	  current.calculatedWidth = width;
	  current.calculatedHeight = height;
	  current.calculatedAngle = angle;
	  // overlay = new USGSOverlay(current.getBounds(), imageURL, map, current);
  };

  function USGSOverlay(bounds, image, map, rectPoly) {
	  this.bounds_ = bounds;
	  this.image_ = image;
	  // this.map_ = map;
	  // this.rectPoly_ = rectPoly

    this.div_ = null;

    this.setMap(map);
	  this.colorBg = "red";
	  this.transform = "rotate(180deg)";
	  this.setColorBg = function (colorBg) {
		  this.colorBg = colorBg;
		  this.div_.style.background = colorBg;
	  };
	  this.setBounds = function (initBounds) {
		  this.bounds_ = initBounds;
	  };
	  this.setTransform = function (deg) {
		  this.transform = `rotate(${deg}deg)`;
	  };
  }

  USGSOverlay.prototype.onAdd = function () {
	  let div = document.createElement("div");
	  div.style.border = "none";
	  div.style.borderWidth = "0px";
	  div.style.position = "absolute";

    let img = document.createElement("img");
	  img.src = this.image_;
	  img.style.width = "100%";
	  img.style.height = "100%";
	  div.appendChild(img);
	  this.div_ = div;

    let panes = this.getPanes();
	  panes.overlayImage.appendChild(this.div_);
  };

  USGSOverlay.prototype.draw = function () {
	  this.colorBg = this.colorBg;
	  this.transform = this.transform;
	  let overlayProjection = this.getProjection();
	  let sw = overlayProjection.fromLatLngToDivPixel(
		  this.bounds_.getSouthWest()
	  );
	  let ne = overlayProjection.fromLatLngToDivPixel(
		  this.bounds_.getNorthEast()
	  );
	  let div = this.div_;
	  div.style.left = sw.x + "px";
	  div.style.top = ne.y + "px";
	  div.style.width = ne.x - sw.x + "px";
	  div.style.height = sw.y - ne.y + "px";
	  if (this.colorBg !== null) {
		  div.style.background = this.colorBg;
	  }
	  div.style.transform = this.transform;
  };

  USGSOverlay.prototype.onRemove = function () {
	  this.div_.parentNode.removeChild(this.div_);
  };

  return (
	  <>
		  <button onClick={addRectangle} className={"button_add"}>
			  Add rectangle
		  </button>
		  {active ? (
			  <Form
				  removePolygon={() => {
					  active.setMap(null);
					  setActive(null);
				  }}
				  closeEditing={() => {
					  setActive(null);
				  }}
				  currentPolygon={active}
				  changeImage={setImageUrl}
				  updateBounds={({ width, height, angle }) =>
					  updateBounds(active, width, height, angle)
				  }
			  />
		  ) : null}
	  </>
  );
};

export default MapContent;