import {Link} from "react-router-dom";
import './styles.scss'
const MainPage = () => {
	return (
		<div className={'links'}>
			<Link to={'/google'}>google map</Link>
			<a href={'/azure.html'}>azure map</a>
		</div>
	);
};

export default MainPage;